@import url(./forms.css);
@import url(./error.css);
@import url(./auth.css);
@import url(./main.css);
@import url(./people.css);
@import url(./general.css);
@import url(./create.css);
@import url(./public.css);
@import url(./filter.css);
@import url(./ribbon.css);
@import url(./select.css);
@import url(./dashboard.css);
@import url(./batch.css);
@import url(./resume.css);

body {
  margin: 0;
  font-family: 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'Microsoft YaHei', 'Segoe UI', 
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #090B0E;
  background-color: #fff;
}

/* 
字体颜色-1: #090B0E
字体颜色-2: #525A66
字体颜色-3: #717780
字体颜色-危险: #AE2A19
按钮背景-绿色: #4BE4B4
按钮背景-蓝色: #005dff
按钮背景-灰色: #F4F5F7
背景-深: #DADBE2
背景-浅: #F5F7FA
边框颜色: #CED2D9
*/

img[src=''],
img:not([src]) {
  opacity: 0
}

a {
  text-decoration: none;
  color: #090B0E;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}
