.general-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.general-avatar {
  width: 46px;
  height: 46px;
  border-radius: 50px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-abbreviation {
  color: #fff;
  font-weight: 600;
}

/* loader */
.general-loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.waveform {
  --uib-size: 30px;
  --uib-speed: 1s;
  --uib-color: black;
  --uib-line-weight: 3.5px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.9);
}

.waveform__bar {
  width: var(--uib-line-weight);
  height: 100%;
  background-color: var(--uib-color);
}

.waveform__bar:nth-child(1) {
  animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.45) infinite;
}

.waveform__bar:nth-child(2) {
  animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.3) infinite;
}

.waveform__bar:nth-child(3) {
  animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.15) infinite;
}

.waveform__bar:nth-child(4) {
  animation: grow var(--uib-speed) ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(0.3);
  }

  50% {
    transform: scaleY(1);
  }
}

/* 二维码 */
.general-qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.general-qrcode {
  width: 220px;
  height: 220px;
}

.general-qrcode img{
  width: 100%;
  height: 100%;
}

.general-qrcode-buttonBox {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.general-qrcode-button1, .general-qrcode-button2 {
  border-radius: 5px;
  padding: 0 20px;
  width: 60px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.general-qrcode-button1 {
  background-color: #090B0E;
}

.general-qrcode-button1 p{
  color: #fff;
}

.general-qrcode-button1:hover {
  background-color: #525A66;
}

.general-qrcode-button2 {
  border: 1px solid #525A66;
}

.general-qrcode-button2:hover {
  background-color: #F5F7FA;
}

.general-qrcode-button2 p{
  color: #090B0E;
}
